import ImageCrop from '../../Components/ImageResize/ImageCrop';
import ImageCropProvider from '../../Components/ImageResize/providers/ImageCropProvider';
import { TextInput,Box, Checkbox,Input, Select, Chip, FormControlLabel, Switch, FormControl, InputLabel, Grid, Hidden,Typography, IconButton, MenuItem } from "@material-ui/core";
import { DatePicker , DateTimePicker} from "@material-ui/pickers";
import { SelfDatePicker } from '../../Components/Datepicker';
import TextField from "@material-ui/core/TextField";
import IconCalendarToday from "@material-ui/icons/CalendarToday";
import AutocompleteField from "../../Components/AutocompleteField";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

import * as React from 'react';
import TimePicker from 'react-time-picker';
import { Autocomplete } from '@material-ui/lab';
import InputMask from "react-input-mask";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
      style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 200,
          'overflow-y':'scroll'
      },
  },
};

const dicCurrency={
  "SGD":"S$",
  "TWD":"NT$",
  "MYR":"RM",
  "THB":"฿",
  "JPY":"¥",
}
export const getCurrency = (key) =>{
  var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
  if(c.length>0){
      return dicCurrency[key];
  }
  return key;
}
export const onValididateInput =function(data, f){
  data.errorFrom = false;
  data.errorTo= false;
  if(!data.valueFrom && data.valueTo){
    data.errorFrom = true;
  }
  else if(data.valueFrom && !data.valueTo){
    data.errorTo = true;
  }
  else if(!f && data.valueFrom && data.valueTo && data.valueFrom > data.valueTo){
    data.errorFrom = true;
  }
}
export const onValididateInputDate =function(data, f){
  data.errorFrom = false;
  data.errorTo= false;

  if(!data.valueFrom && data.valueTo){
    data.errorFrom = true;
  }
  else if(data.valueFrom && !data.valueTo){
    data.errorTo = true;
  }
  else if(!f && data.valueFrom && data.valueTo){
    const fromDateStr  = moment(new Date(data.valueFrom), 'ddd MMM DD YYYY HH:mm:ss Z').format('YYYY-MM-DD');
    const toDateStr  = moment(new Date(data.valueTo), 'ddd MMM DD YYYY HH:mm:ss Z').format('YYYY-MM-DD');    
    if(fromDateStr > toDateStr){
      data.errorFrom = true;
    }
  }
}
export const CourseImageCrop = ( {onImageUrlChange, onDone,onCancel }) => {
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <ImageCropProvider>
        <ImageCrop onImageUrlChange={onImageUrlChange} onDone={onDone} onCancel={onCancel} />
      </ImageCropProvider>
    </div>
  );
};

const CheckboxItem = ({ data, onChange }) => {
  const [isChecked, setIsChecked] = React.useState(data.checked);
  const handleCheckboxChange = (value) => {
    data.checked = value;
    setIsChecked(value);
    onChange(data.code,value);
  };

return (<FormControlLabel control={
<Checkbox color="primary" checked={isChecked} value={isChecked} 
  onChange={(event) => {handleCheckboxChange(event.target.checked);}}/>} label={data.text}/>)
};
export const InputNumbericItem=({data, onEditable, onChange })=>{
  const [numberFrom, setNumberFrom] = React.useState(data.valueFrom);
  const [numberTo, setNumberTo] = React.useState(data.valueTo);
  const handleNumberFromChanged = (event) => {
    var value = parseInt(event.target.value.slice(0, 3));
    data.valueFrom = value;
    setNumberFrom(value);
    onChange(data.code ,value);
    onValididateInput(data);
  
  };
  const handleNumberToChanged = (event) => {
    var value = parseInt(event.target.value.slice(0, 3));
    data.valueTo = value;
    setNumberTo(value);
    onChange(data.code ,value);
    onValididateInput(data);
  };
 return (
  <Box mt={1}>
    <table width={300} className="number-range">
      <tr>
          <td>
          <InputMask 
           mask="999"
           maskChar=""
           fullWidth
           InputProps={{ inputProps: { min: 0, max: 999 }}}
           min={0}
           max={999}
           error={data.errorFrom}
           type="number"  aria-readonly={true} disabled={!onEditable} 
           onWheel={(e) => e.target.blur()}
           value={numberFrom}
           onChange={handleNumberFromChanged}
           className="number">
            {() => <TextField className="number" error={data.errorFrom}/>}
           </InputMask>
          </td>
          <td>{data.labelFrom}</td>
          <td>
          <InputMask InputProps={{ inputProps: { min: 0, max: 999 } }}
           mask="999"
           maskChar=""
           fullWidth
           min={0}
           max={999}
           error={data.errorTo}
           type="number"  aria-readonly={true} disabled={!onEditable} 
           onWheel={(e) => e.target.blur()}
           value={numberTo}
           className="number"
           onChange={handleNumberToChanged}
            >
                {() => <TextField className="number" error={data.errorTo}/>}
           </InputMask>
          </td>
          <td>{data.labelTo}</td>
      </tr>
    </table>
  </Box>
 );
}

export const InputNumbericRange=({onEditable,dataList})=>{
  const onValueChanged=()=>{
  }
 return (
  <Box mt={1}>
     {
      dataList.map((row) => {
					return (<InputNumbericItem  onEditable={onEditable} data={row} onChange={onValueChanged}/>)
      })}
  </Box>
 );
}
export const InputChecklistRange=({onEditable,dataList})=>{
  const onValueChanged=(code,value)=>{
    //console.log(dataList);
  }

  return (
    <Box mt={1}>
      {
      dataList.map((row) => {
					return (<CheckboxItem  data={row} onChange={onValueChanged}/>)
      })}
    </Box>
  );
}
const InputDateItem=({ data, onEditable, onChange }) => {
  const [dateFrom, setDateFrom] = React.useState(data.valueFrom);
  const [dateTo, setDateTo] = React.useState(data.valueTo);
  const handleDateFromChanged = (event) => {
    var value = null;
    if(event!= null){
       value = event.valueOf();
    }
    data.valueFrom = value;
    setDateFrom(value);
    onChange(data.code ,value);
    onValididateInputDate(data);
  };
  const handleDateToChanged = (event) => {
    var value = null;
    if(event!= null){
       value = event.valueOf();
    }
    data.valueTo = value;
    setDateTo(value);
    onChange(data.code ,value);
    onValididateInputDate(data);
  };
  return (
    <div>
        <table className="date-range">
            <tr>
                <td width={150}>
                <KeyboardDatePicker
                format="YYYY-MM-DD"
                margin="normal"
                disabled={!onEditable} 
                value={dateFrom}
                error={data.errorFrom}
                onChange={handleDateFromChanged}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
               />
                </td>
                <td className="date-range-period">~</td>
                <td width={150}>
                  <KeyboardDatePicker 
                  format="YYYY-MM-DD"
                  margin="normal"
                  disabled={!onEditable} 
                   value={dateTo} 
                   error={data.errorTo}
                   onChange={handleDateToChanged}
                   KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                 />

                </td>
            </tr>
        </table>
    </div>
  );
}

export const InputDateRange=({onEditable,dataList})=>{
  const onValueChanged=()=>{
  }
  return (
    <Box mt={1}>
       {
      dataList.map((row) => {
					return (<InputDateItem  onEditable={onEditable} data={row} onChange={onValueChanged}/>)
      })}
    </Box>
  );
 }

 const InputTimeItem=({ data, onEditable, onChange }) => {
  const [dateFrom, setDateFrom] = React.useState(data.valueFrom);
  const [dateTo, setDateTo] = React.useState(data.valueTo);
  const handleDateFromChanged = (event) => {
    var value = null;
    if(event!= null){
       value = event.valueOf();
    }
    data.valueFrom = value;
    setDateFrom(value);
    onChange(data.code ,value);
  };
  const handleDateToChanged = (event) => {
    var value = null;
    if(event!= null){
       value = event.valueOf();
    }
    data.valueTo = value;
    setDateTo(value);
    onChange(data.code ,value);
  };
  return (
    <Box mt={1}>
        <table width={300}>
            <tr>
                <td>
                <KeyboardTimePicker
                clearable={true}
                disabled={!onEditable}  
                ampm={false}
                format="HH:mm"
                shoPicker={false}
                minutesStep={15}
                value={data.valueFrom}
                InputProps={{
                  readOnly: true, 
                }}
                onChange={handleDateFromChanged}
                />
                </td>
                <td>{data.labelFrom}</td>
                <td>
                  <KeyboardTimePicker 
                  clearable={true}
                  disabled={!onEditable}  
                  ampm={false}
                  format="HH:mm"
                  minutesStep={15}
                  value={data.valueTo}
                  InputProps={{
                    readOnly: true, 
                  }}
                   onChange={handleDateToChanged} />
                </td>
                <td width={40}>{data.labelTo}</td>
            </tr>
        </table>
    </Box>
  );
}
export const InputTimeRange=({onEditable,dataList})=>{
  
  const onValueChanged=()=>{
  }
  return (
    <Box mt={1}>
       {
      dataList.map((row) => {
					return (<InputTimeItem  onEditable={onEditable} data={row} onChange={onValueChanged}/>)
      })}
    </Box>
  );
 }

 const InputTimeDropdowwnItem=({ data,listDateType, onEditable, onChange,fixWidth=450 }) => {
  const popupRef = React.useRef(null);
  const timeListRange = generateTime24HArray();
  const [valueFrom, setValueFrom] = React.useState(data.valueFrom);
  const [valueTo, setValueTo] = React.useState(data.valueTo);
  var timeout =-1;
  const openPopup =()=>{
    window.clearTimeout(timeout);
    timeout=window.setTimeout(function(){
    var popup =document.querySelector(".MuiAutocomplete-popper");
    if(popup){
      popup.classList.add('adjust-popup-size');
    }
  },1);
  }
  const handleDateFromChanged = (event) => {
    var value = null;
    if(event!= null){
       value =(event);
    }
    data.valueFrom = value;
    setValueFrom(value);
    onChange(data.code ,value);
    onValididateInput(data, true);
  };
  const handleDateToChanged = (event) => {
    var value = null;
    if(event!= null){
       value =(event);
    }
    data.valueTo = value;
    setValueTo(value);
    onChange(data.code ,value);
    onValididateInput(data, true);
  };
  return (
    <Box mt={1} className="range-combobox">
        <table width={fixWidth}>
            <tr>
                <td>
                <AutocompleteField
                  className="col1"
                  disableClearable={false}
                  variant='standard'
                  fullWidth
                  disabled={!onEditable}  
                  value={data.valueFrom}
                  optionList={listDateType}
                  MenuProps={MenuProps}
                  errorValue ={data.errorFrom}
                  onOpen={openPopup}
                  onChange={handleDateFromChanged} >
                </AutocompleteField>
                </td>
                <td width={15}>{data.labelFrom}</td>
                <td width={90}>
                <AutocompleteField
                  className="col2"
                  disableClearable={false}
                  variant='standard'
                  fullWidth
                  disabled={!onEditable}  
                  value={data.valueTo}
                  optionList={timeListRange}
                  errorValue ={data.errorTo}
                  MenuProps={MenuProps}
                  input={<Input/>}
                  onOpen={openPopup}
                  onChange={handleDateToChanged} >
                </AutocompleteField>
                </td>
                <td width={40}>{data.labelTo}</td>
            </tr>
        </table>
    </Box>
  );
}
export const InputTimeDropdownRange=({onEditable,dataList,listDateType, fixWidth})=>{
  const onValueChanged=()=>{
  }
  return (
    <Box mt={1}>
       {
      dataList.map((row) => {
					return (<InputTimeDropdowwnItem  fixWidth={fixWidth} listDateType={listDateType} onEditable={onEditable} data={row} onChange={onValueChanged}/>)
      })}
    </Box>
  );
 }

 export const generateTimeArray=(allowNull)=> {
  const startTime = 6 * 60; // 06:00 in minutes
  const endTime = 29 * 60 + 45; // 29:45 in minutes
  const interval = 15; // 15 minutes

  var timeArray = [];
  if(allowNull){
    timeArray.push({ code: '', text: '', value:'', nextDate:'', checked:false });
  }
  for (let i = startTime; i <= endTime; i += interval) {
      const hours = Math.floor(i / 60).toString().padStart(2, '0');
      const minutes = (i % 60).toString().padStart(2, '0');
      const hhmm = `${hours}:${minutes}`;
      const value = `${hours}${minutes}`;
      const nextDate = i >= 24 * 60 ? 1 : 0;
      const checked = false; // You can set this value as needed

      timeArray.push({ code: value, text: hhmm,label: hhmm, value:value, nextDate:nextDate, checked:checked });
  }
  return timeArray;
}
export const generateTime24HArray=(allowNull)=> {
  const startTime = 0 * 60; // 06:00 in minutes
  const endTime = 23 * 60 + 45; // 29:45 in minutes
  const interval = 15; // 15 minutes

  var timeArray = [];
  if(allowNull){
    timeArray.push({ code: '', text: '', value:'', nextDate:'', checked:false });
  }
  for (let i = startTime; i <= endTime; i += interval) {
      const hours = Math.floor(i / 60).toString().padStart(2, '0');
      const minutes = (i % 60).toString().padStart(2, '0');
      const hhmm = `${hours}:${minutes}`;
      const value = `${hours}${minutes}`;
      const nextDate = i >= 24 * 60 ? 1 : 0;
      const checked = false; // You can set this value as needed

      timeArray.push({ code: value, text: hhmm,label: hhmm, value:value, nextDate:nextDate, checked:checked });
  }
  return timeArray;
}
const tableSeats =["private","table","counter","outdoor_seat","tatami_seat","semi_private","horigotatsu","sofa_seat","bar_counter","none","takeaway"];
export const createSeatTypes=(tables,seatTypeLanguage,t)=>{
    var SeatTypes=[];
    for (const tbl of tables) {
      var lang= seatTypeLanguage.hasOwnProperty(tbl.key_name)? t(seatTypeLanguage[tbl.key_name]):tbl.key_name;
      var tableItem={
       code :tbl.key_name,
       text:lang,
       checked:false,
       index: tableSeats.indexOf(tbl.key_name)
      };

      SeatTypes.push(tableItem);
   }
   SeatTypes = SeatTypes.sort((a, b) => a.index - b.index);
   return SeatTypes;
}

export const updateCheckedStatus=(objects, codeStr) =>{
  
  const codeValues = codeStr.split(", ");
  return objects.map((obj) => ({
      ...obj,
      checked: codeValues.includes(obj.code),
  }));
}
export const updateDateRange=(objects, dataStr) =>{
  
  for (let index = 0; index < dataStr.length; index++) {
    const element = dataStr[index];
    objects[index].valueFrom = element.fromDate;
    objects[index].valueTo = element.toDate;
  }
  return objects;
}
export const joinCodes=(list)=>{
  const separator = ", ";
  const codes = list
  .filter((item) => item.checked) 
  .map((item) => item.code);
  return codes.join(separator);
}

export default CourseImageCrop;
