// import負責創建store的函式createStore
import { createStore, applyMiddleware } from "redux";
// 引入createEpicMiddleware
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import Epics from "./Epics";
import Reducers from "./Reducers";
import actions from "./Actions";
import def from "./def";
let innerstore = null;
const epicMiddleware = createEpicMiddleware();
if (process.env.NODE_ENV === "development") {
    innerstore = createStore(Reducers, composeWithDevTools(applyMiddleware(thunk, epicMiddleware)));
} else {
    innerstore = createStore(Reducers, applyMiddleware(thunk, epicMiddleware));
}
epicMiddleware.run(Epics);
export const DEF = def;
export const Actions = actions;
export const store = innerstore;
// console.log(Actions);
// eslint-disable-next-line
const Index = {
    DEF,
    store,
    Actions: actions,
};

export default Index;
