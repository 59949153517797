// import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { UseAppContext } from "../Context/App";
import Actions from "../Actions/Actions";
import {
    ManageUser,
    PageFilter,
    CompanyUserFilter,
    ReservationFilter,
    CompanyFilter,
    StripeRestaurant,
    FineBody,
    RefundBody,
    FineNotifyBody,
    LoginBody,
    CompanyBody,
    SimpleCompany,
    SimpleRestaurant,
    SimpleRestaurantOptions,
    MenuOption,
    ListWithCount,
    NoDataResponse,
    ManagerUserBody,
    Course,
    ResetPasswordBody,
    ToretaReservationSearchBody,
    ToretaReservationsDaysResponse,
    ShopInfo,
    YoyakuBody,
    YoyakuFullBody,
    RestaurantCourseSetting,
    OtpBody,
    EmailTrackingResult,
} from "../common/interface";
import moment from "moment";
export interface ToretaError {
    status: number;
    detail: string;
}

const ToretaApi = () => {
    //const currentLanguage: string = useSelector((state: any) => state.lang);
    // const shop: ShopDetail = useSelector((state: any) => state.shop);
    const user: ManageUser = useSelector((state: any) => state.user);
    const { t } = useTranslation();
    const errors = t("MANAGER.ERRORS", { returnObjects: true });
    const isManageLevel = user.admin || user.companyOwner || user.restaurantOwner;
    const isAdmin = user.admin ;

    // const history = useHistory(); 
    const errorHandle = (error: any) => {
        console.error("errorHandle",error);
        const response = error.response;
        if (response) {
            if (response.data.code) {
                Swal.fire({
                    icon: "error",
                    title: t(`MANAGER.ERRORMESSAGE.${response.data.code}`),
                });
            } else if (response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.NOT_LOING"),
                });
                checkUserLogin();
            } else if (response.status === 504 || error.response.status === 503 || error.response.status === 502) {
                Swal.fire({
                    icon: "error",
                    title: t("ERROR.SERVER_UNAVAILABLE"),
                });
                checkUserLogin();
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.SYSTEM_ERROR"),
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: error.message,
            });
        }
    };
    const refundErrorHandle = (error: any) => {
        // debugger;
        if (error.response.data.type === "StripeInvalidRequestError") {
            const data = error.response.data;
            Swal.fire({
                icon: "error",
                title: `${data.code}`,
                html: `<a href="${data.doc_url}" target="_blank">${data.code}</a>`,
            });
        } else {
            errorHandle(error);
        }
    };
    const ConfirmOTP = async (otp:string)=>{
        Actions.loadingControl(true);
        return await axios
            .post(`/auth/loginbyOtp`, {otp:otp } as OtpBody , 
            {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true 
            })
            .then((response) => {
                if (response.data === 0) {
                    checkUserLogin();
                } 
                else  if (response.data === -1) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.OTP.OTP-INVALID"),
                    });
                }
                else  if (response.data === -2) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.OTP.OTP-EXPIRED"),
                    });
                }
                else  if (response.data === -99) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.OTP.USER-LOCKED"),
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.ERRORMESSAGE.LOGIN-001"),
                    });
                    return;
                }
            })
            .catch((error) => {
                //console.error("UserLogin catch:",error);
                //console.log(error);
                if(error.response.status === 504 || error.response.status === 503 || error.response.status === 502){
                    Swal.fire({
                        icon: "error",
                        title: t("ERROR.SERVER_UNAVAILABLE"),
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.ERRORMESSAGE.LOGIN-001"),
                    });
                }
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    }
    const UserLogin = async (email: string, pass: string, language: string): Promise<void> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/auth/login`, 
            { email, pass, language} as LoginBody , 
            {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true 
            })
            .then((response) => {
                if (response.data.status === "ok") {
                    checkUserLogin();
                } else {
                    if(response.data.err && response.data.err === -99){
                        Swal.fire({
                            icon: "info",
                            title: t("MANAGER.ERRORMESSAGE.LOGIN-002_TITLE"),
                            //text: t("MANAGER.ERRORMESSAGE.LOGIN-002_MSG"),
                            html: '<div style="text-align: left">' + t("MANAGER.ERRORMESSAGE.LOGIN-002_MSG") + '</div>',
                            customClass: {
                              icon: 'no-border'
                            }
                        });
                    }
                    else{
                        Swal.fire({
                            icon: "error",
                            title: t("MANAGER.ERRORMESSAGE.LOGIN-001"),
                        });
                    }
                    return;
                }
            })
            .catch((error) => {
                //console.error("UserLogin catch:",error);
               
                if(error.response.status === 504 || error.response.status === 503 || error.response.status === 502){
                    Swal.fire({
                        icon: "error",
                        title: t("ERROR.SERVER_UNAVAILABLE"),
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.ERRORMESSAGE.LOGIN-001"),
                    });
                }
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const UserLogout = async (): Promise<void> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/auth/logout`,
                { withCredentials: true },
            )
            .then((response) => {
                checkUserLogin();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const checkUserLogin = async (): Promise<void> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/auth/login`,
                { withCredentials: true },
            )
            .then((response) => {
                //console.log(response.data);
                //console.log('trigger update user state event..');
                Actions.updateUser(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                //console.log('trigger loadingControl event..');
                Actions.loadingControl(false);
            });
    };
    const getCompanyList = async (): Promise<MenuOption[]> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/list/company`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data.map((a: SimpleCompany) => ({
                    label: a.name,
                    value: a.id,
                    country:a.country,
                }));
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getRestaurantLink = async (publicKey: string): Promise<string> => {
        if (!publicKey) {
            return "";
        }
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/restaurantlink/${publicKey}`)
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getRestaurantOptions = async (companyId: number | null): Promise<MenuOption[]> => {
        if (!companyId) {
            return [];
        }
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/list/${companyId}/restaurantoptions`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data.map((a: SimpleRestaurantOptions) => ({
                    label: a.name,
                    value: a.id,
                    enabled: a.enabled,
                }));
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getRestaurantList = async (companyId: number | null): Promise<MenuOption[]> => {
        if (!companyId) {
            return [];
        }
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/list/${companyId}/restaurant`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data.map((a: SimpleRestaurant) => ({
                    label: a.name,
                    value: a.id,
                }));
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getReservationList = async (restaurantId: number | null, filter: ReservationFilter): Promise<ListWithCount> => {
        if (!restaurantId) {
            return { count: 0, rows: [] };
        }
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/reservations/${restaurantId}`, filter,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getUserList = async (filter: CompanyUserFilter): Promise<ListWithCount> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/companyuserlist`, filter,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getRestaurantStripeList = async (filter: PageFilter): Promise<ListWithCount> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/striperestaurants`, filter,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateRestaurantStripe = async (data: StripeRestaurant, file: File | null, successAction: () => void): Promise<void> => {
        Actions.loadingControl(true);
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        formData.append("json", JSON.stringify(data));
        return await axios
            .patch(`/manager/striperestaurants`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true ,
            })
            .then((response) => {
                return response.data;
            })
            .then((data: NoDataResponse) => {
                if (data.status === "ok") {
                    Swal.fire({
                        title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                    });
                    successAction && successAction();
                }
            })
            .catch((error) => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: error,
                });
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getUserInfo = async (userId: number): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/companyuser/${userId}`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateUserInfo = async (userId: number | null, data: ManagerUserBody, success: () => void): Promise<any> => {
        Actions.loadingControl(true);
        if (!userId) {
            return await axios
                .post(`/manager/companyuser`, data,
                { withCredentials: true },
            )
                .then((response) => {
                    if (response.data.status === "ok") {
                        Swal.fire({
                            title: t("MANAGER.MESSAGE.REGISTERED_SUCCESS"),
                        });
                        success();
                    } else {
                        if (errors[response.data]) {
                            Swal.fire({
                                icon: "error",
                                title: errors[response.data],
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: t("MANAGER.MESSAGE.UPDATE_FAIL"),
                            });
                        }
                    }
                })
                .catch(errorHandle)
                .finally(() => {
                    Actions.loadingControl(false);
                });
        } else {
            return await axios
                .patch(`/manager/companyuser/${userId}`, data,
                { withCredentials: true },
            )
                .then((response) => {
                    if (response.data.status === "ok") {
                        Swal.fire({
                            title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                        });
                        success();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: t("MANAGER.MESSAGE.UPDATE_FAIL"),
                        });
                    }
                })
                .catch(errorHandle)
                .finally(() => {
                    Actions.loadingControl(false);
                });
        }
    };
    const forceResetUserPassword = async (username: string, success: () => void): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/auth/forceResetUserPassword/${username}`,
            { withCredentials: true },
        )
            .then((response) => {
                success();
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };

    const deleteUser = async (userId: number): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .delete(`/manager/companyuser/${userId}`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const refund = async (companyId: number, reservationId: number, fee: number): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/refund/${companyId}/${reservationId}`, { fee } as RefundBody,
            { withCredentials: true },
        )
            .then((response) => {
                Swal.fire({
                    title: t("MANAGER.RESERVE.REFUND_SUCCESS"),
                });
            })
            .catch(refundErrorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const fine = async (companyId: number, reservationId: number, fee: number): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/fine/${companyId}/${reservationId}`, { fee } as FineBody,
            { withCredentials: true },
        )
            .then((response) => {
                if (response.data.status === "ok") {
                    Swal.fire({
                        title: t("MANAGER.RESERVE.FINE_SUCCESS"),
                    });
                } else {
                    Swal.fire({
                        title: t("MANAGER.RESERVE.FINE_WAITING"),
                    });
                }
            })
            .catch(refundErrorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const fine_notify = async (companyId: number, reservationId: number, email: string): Promise<any> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/finenotify/${companyId}/${reservationId}`, { email } as FineNotifyBody,
            { withCredentials: true },
        )
            .then((response) => {
                if (response.data.status === "ok") {
                    Swal.fire({
                        title: t("MANAGER.RESERVE.NOTIFY_SUCCESS"),
                    });
                } else {
                    Swal.fire({
                        title: t("MANAGER.RESERVE.NOTIFY_FAIL"),
                    });
                }
            })
            .catch(refundErrorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getDebitruleList = async (restaurantId: number, filter: PageFilter) => {
        if (!restaurantId) {
            return null;
        }
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/debitrule/${restaurantId}`, filter,
            { withCredentials: true },
        )
            .then((response) => {
                // response.data.rows.forEach((a: any) => {
                //     a.dates = a.dates.map((b: any) => b.date);
                // });
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateDebitrule = async (restaurantId: number, data: any, action: any) => {
        if (!restaurantId) {
            return;
        }
        Actions.loadingControl(true);
        return await axios
            .patch(`/manager/debitrule/${restaurantId}`, data,
            { withCredentials: true },
        )
            .then((response) => {
                action && action();
                Swal.fire({
                    title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                });
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const deleteDebitrule = async (restaurantId: number, id: number, action: any) => {
        if (!restaurantId) {
            return;
        }
        Actions.loadingControl(true);
        return await axios
            .delete(`/manager/debitrule/${restaurantId}/${id}`,
            { withCredentials: true },
        )
            .then((response) => {
                action && action();
                Swal.fire({
                    title: t("MANAGER.MESSAGE.DELETE_SUCCESS"),
                });
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getMonthDebitRule = async (restaurantId: number, yearMonth: string) => {
        if (!restaurantId) {
            return;
        }
        Actions.loadingControl(true);
        return await axios
            .get(`/manager/monthdebitrule/${restaurantId}/${yearMonth}`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateMonthDebitRule = async (restaurantId: number, data: any, action: any) => {
        if (!restaurantId) {
            return;
        }
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/monthdebitruleupdate/${restaurantId}`, data,
            { withCredentials: true },
        )
            .then((response) => {
                action && action();
                Swal.fire({
                    title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                });
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };

    const updateWeekDayDebitRule = async (
      restaurantId: number,
      data: any,
      action: any
    ) => {
      if (!restaurantId) {
        return;
      }
      Actions.loadingControl(true);
      return await axios
        .post(`/manager/weekdaydebitruleupdate/${restaurantId}`, data, {
          withCredentials: true,
        })
        .then((response) => {
          action && action();
          Swal.fire({
            title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
          });
          return response.data;
        })
        .catch(errorHandle)
        .finally(() => {
          Actions.loadingControl(false);
        });
    };

    const getCompanyPage = async (filter: CompanyFilter): Promise<ListWithCount> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/page/company`, filter,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateCompany = async (data: CompanyBody, action: () => void): Promise<void> => {
        Actions.loadingControl(true);
        const senddata = { ...data };
        senddata.file = null;
        const formData = new FormData();
        if (data.file) {
            formData.append("file", data.file);
        }
        formData.append("json", JSON.stringify(senddata));
        return await axios
            .post(`/manager/page/companyupdate`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true ,
            })
            .then((response) => {
                action && action();
                if (data.id) {
                    Swal.fire({
                        title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                    });
                } else {
                    Swal.fire({
                        title: t("MANAGER.MESSAGE.INSERT_SUCCESS"),
                    });
                }
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const updateRestaurantsWithCompanyAPIToken = async (companyId: number): Promise<NoDataResponse> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/manager/page/restaurantsupdate/${companyId}`,
            { withCredentials: true },
        )
            .then((response) => {
                Swal.fire({
                    title: t("MANAGER.MESSAGE.UPDATE_SUCCESS"),
                });
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getShopCourses = async (publicKey: string): Promise<Course[]> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/courses`,
            { withCredentials: true },
        )
            .then((response) => {
                return response.data;
            })
            .catch(errorHandle)
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const getShopFullCourses = async (publicKey: string): Promise<Course[]> => {
      Actions.loadingControl(true);
      return await axios
        .get(`/manager/courses/${publicKey}`, { withCredentials: true })
        .then((response) => {
          return response.data;
        })
        .catch(errorHandle)
        .finally(() => {
          Actions.loadingControl(false);
        });
    };
    const searchTrackingEmail = async (fromDate:number| undefined , todate: number|undefined): Promise<EmailTrackingResult[]> => {
        Actions.loadingControl(true);
        return await axios
          .post(`/manager/searchTrackingEmail`, 
          {
            fromDate: fromDate,
            toDate: todate
          },
          { withCredentials: true })
          .then((response) => {
            return response.data;
          })
          .catch(errorHandle)
          .finally(() => {
            Actions.loadingControl(false);
          });
     };

    const getShopSettingCourses = async (publicKey: string,restaurant_id:number): Promise<RestaurantCourseSetting[]> => {
        Actions.loadingControl(true);
        return await axios
          .get(`/manager/settingcourses/${publicKey}/${restaurant_id}`, { withCredentials: true })
          .then((response) => {
            var courses= response.data;
            const hasDeletedCourse = courses.some((item:any)=> {return item.course_name === null || item.course_name === '';}); 
            if(hasDeletedCourse){
                    courses = courses.filter((item:any) =>{ return item.course_name !== null && item.course_name !== '';});
            }
            return courses;
          })
          .catch(errorHandle)
          .finally(() => {
            Actions.loadingControl(false);
          });
      };
      const getShopSettingCourseDetail = async (id:number): Promise<RestaurantCourseSetting> => {
        Actions.loadingControl(true);
        return await axios
          .get(`/manager/settingcourse/${id}`, { withCredentials: true })
          .then((response) => {
            return response.data;
          })
          .catch(errorHandle)
          .finally(() => {
            Actions.loadingControl(false);
          });
      };
      const updateShopSettingCourseDetail = async (data:RestaurantCourseSetting,file: File | null, successAction: () => void): Promise<string> => {
        Actions.loadingControl(true);
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        formData.append("json", JSON.stringify(data));
        //console.log(formData);
        return await axios
          .patch(`/manager/updatesettingcourse`, formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }, withCredentials: true})
          .then((response) => {
            successAction && successAction();
            return response.data;
          })
          .catch(errorHandle)
          .finally(() => {
            Actions.loadingControl(false);
          });
      };
      
    const isCodeUsed = async (code: string): Promise<Boolean> => {
        Actions.loadingControl(true);
        return await axios
            .get(`/auth/iscodeused/` + code,
            { withCredentials: true },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.data.isUsed;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.ERRORMESSAGE.RESETPASS-001"),
                    });
                    return true;
                }
            })
            .catch((error) => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.RESETPASS-001"),
                });
                return true;
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    }
    const resetPasswordByCode = async (password: string, code: string): Promise<Boolean> => {
        Actions.loadingControl(true);
        // return await axios
        let isSuccess = false;
        await axios
            .patch(`/auth/resetpasswordbycode/` + code , { password } as ResetPasswordBody,
            { withCredentials: true },
        )
            .then((response) => {
                if (response.status === 200) {
                    if(response.data.success) {
                        Swal.fire({
                            title: t("MANAGER.MESSAGE.RESETPASS_SUCCESS"),
                        });
                        isSuccess = true;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: t("MANAGER.ERRORMESSAGE.RESETPASS-001"),
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.ERRORMESSAGE.RESETPASS-001"),
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.RESETPASS-001"),
                });
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
        return isSuccess;
    };
    const AddReserveQueue = async (publicKey:string, data: YoyakuFullBody): Promise<any> => {
        Actions.loadingControl(true);
        return await axios.post(`/api/${publicKey}/reservequeue`, data).finally(() => {
            Actions.loadingControl(false);
        });
    }; 
    const AddReserveByStaff = async (publicKey:string, initValue: YoyakuBody, nextAction: any, failAction: any) => {
        const senddata: YoyakuFullBody = {
            reservationIn: initValue,
            //lang: currentLanguage,
        };
        //console.log("AddReserveByStaff nextAction:",nextAction,"sending request:",senddata);

        return AddReserveQueue(publicKey,senddata)
            .then((response) => {

                const data = response.data;
                //console.log("AddReserveByStaff response:",data);

                if (data.pay_url) {
                    Swal.fire({ title: t("FINISH.CONTINUE_SMS"), timer: 5000 });
                    nextAction && nextAction();
                } else {
                    Swal.fire({ title: t("APIERROR.WEB_RESERVATIONS.503"), timer: 5000 });
                    failAction && failAction();
                }
            })
            .catch((error) => {
                console.error("AddReserveByStaff response error:",error);
                Swal.fire({ title: t("APIERROR.WEB_RESERVATIONS.503"), timer: 5000 });
                failAction && failAction();
            });
    };
    //取得餐廳設定值
    const GetShopDetail = async (publicKey: string) => {
        // console.log(Actions);
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/info`)
            .then((response) => {
                //console.log("GetShopDetail:",response.data);
                if(response.data.enabled){
                    Actions.updateShopDetail(response.data);
                }else{
                    //console.log("shop disabled:",response.data);
                }
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    //取得套餐和座位類型
    const GetShopOther = async (publicKey: string) => {
        Actions.loadingControl(true);
        return await axios
            .get(`/api/${publicKey}/other`)
            .then((response) => {
                const data: ShopInfo = response.data;
                data.courses?.forEach((a) => {
                    if (a.price) {
                        a.price = parseFloat(`${a.price}`).toFixed(2);
                    }
                });
                //console.log("GetShopOther:",data);
                Actions.updateShopOther(data);
                return data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    const GetEmptySetas = async (publicKey: string,data: ToretaReservationSearchBody): Promise<ToretaReservationsDaysResponse> => {
        Actions.loadingControl(true);
        return await axios
            .post(`/api/${publicKey}/seats`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                Actions.loadingControl(false);
            });
    };
    function addPreZero(num: number) {
        return ("00" + num).slice(-2);
    }

    const transformTimeNumber24h = (value: number | null): string => {
      if (!value) {
        return "";
      }
      const hour = Math.floor(value / 3600);
      return (
        addPreZero(hour >= 24 ? hour - 24 : hour) +
        ":" +
        addPreZero(Math.floor((value % 3600) / 60))
      );
    };
    const transformTimeNumber12h = (value: number | null): string => {
      if (!value) {
        return "";
      }
      const hour = Math.floor(value / 3600);

      if (hour < 12 || hour >= 24) {
        return (
          addPreZero(hour >= 24 ? hour - 24 : hour) +
          ":" +
          addPreZero(Math.floor((value % 3600) / 60)) +
          t("AM")
        );
      } else {
        return (
          addPreZero(hour > 12 ? hour - 12 : 12) +
          ":" +
          addPreZero(Math.floor((value % 3600) / 60)) +
          t("PM")
        );
      }
    };
    return {
      getShopSettingCourses,
      getShopSettingCourseDetail,
      updateShopSettingCourseDetail,
      getShopCourses,
      getShopFullCourses,
      getCompanyPage,
      refund,
      getUserInfo,
      updateUserInfo,
      forceResetUserPassword,
      UserLogin,
      ConfirmOTP,
      checkUserLogin,
      UserLogout,
      getCompanyList,
      getRestaurantStripeList,
      updateRestaurantStripe,
      getRestaurantList,
      getRestaurantOptions, //for user settings
      getReservationList,
      getRestaurantLink,
      getDebitruleList,
      updateDebitrule,
      deleteDebitrule,
      getMonthDebitRule,
      updateMonthDebitRule,
      updateWeekDayDebitRule,
      getUserList,
      deleteUser,
      updateCompany,
      updateRestaurantsWithCompanyAPIToken,
      fine,
      fine_notify,
      isManageLevel,
      isAdmin,
      isCodeUsed,
      resetPasswordByCode,
      GetEmptySetas,
      transformTimeNumber24h,
      transformTimeNumber12h,
      GetShopDetail,
      GetShopOther,
      AddReserveByStaff, searchTrackingEmail
    };
};

export default ToretaApi;
