import React, { useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../ToretaApi";
import Dialog from "./Dialog";

// import { ReservationFilter } from "../../common/interface";
import AutocompleteField from "../../Components/AutocompleteField";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        container: {
            maxHeight: window.innerHeight - 280,
            [theme.breakpoints.down("md")]: {
                maxHeight: window.innerHeight - 280,
            },
        },
    })
);
type Props = {};

const StickyTable: React.FunctionComponent<Props> = () => {
    const user: any = useSelector((state: any) => state.user);
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        toretaApi.getCompanyList().then((data) => {
            setCompanyList(data);

            if (data.length === 1) {
                setCompany(data[0].value);
            }
        });
        // eslint-disable-next-line
    }, []);
    const [companyList, setCompanyList] = React.useState<any>(null);
    const [company, setCompany] = React.useState<number | null>(null);

    const [pageData, setPageData] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [dialogStatus, setDialogStatus] = React.useState<any>({ open: false, data: null });

    const dialogClose = () => {
        setDialogStatus({ open: false, data: null });
    };
    const dialogOpen = (data: any | null) => {
        // console.log("dialogOpen",data);
        setDialogStatus({ open: true, data: { ...data } });
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const storeCompanyCountry = (companyid: number|null) => {
        
        var company=companyList.filter((item:any)=>{return item.value==companyid});
        if(company.length>0){
            user.select_country=company[0].country;
        }
    };
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const { isAdmin } = toretaApi;
    const clickButton = () => {
        if (page === 0) {
            search();
        } else {
            setPage(0);
        }
    };

    const search = () => {
        const sendFilter = {
            companyId: company,
            page: page,
            perPage: rowsPerPage,
        };
        toretaApi.getRestaurantStripeList(sendFilter).then(setPageData);
    };

    useEffect(() => {
        search();
        // eslint-disable-next-line
    }, [company, page, rowsPerPage]);

    return (
        <>
            <Dialog open={dialogStatus.open} data={dialogStatus.data} close={dialogClose} search={search} />
            <Grid item xs={6} lg={3}>
                <AutocompleteField
                    label={t("MANAGER.FIELD.COMPANY")}
                    optionList={companyList}
                    value={company}
                    onChange={(value: any) => {
                        setCompany(value);
                        storeCompanyCountry(value);
                    }}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <Button variant="contained" color="primary" onClick={clickButton}>
                    {t("MANAGER.ACTION.SEARCH")}
                </Button>
            </Grid>
            {company && pageData && pageData.rows ? (
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>{t("MANAGER.FIELD.RESTAURANT")}</TableCell> */}
                                        <TableCell>{t("MANAGER.FIELD.RESTAURANT")}</TableCell>
                                        <TableCell>{t("MANAGER.FIELD.PUBLIC_KEY")}</TableCell>
                                        <TableCell>{t("MANAGER.FIELD.CURRENCY")}</TableCell>
                                        <TableCell>{t("MANAGER.FIELD.COUNTRY")}</TableCell>
                                        {isManageLevel ? (
                                            <>
                                                <TableCell>{t("MANAGER.FIELD.STRIPE_SK")}</TableCell>
                                                <TableCell>{t("MANAGER.FIELD.STRIPE_PK")}</TableCell>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {isAdmin ? (
                                            <>
                                                <TableCell>{t("MANAGER.FIELD.STATUS")}</TableCell>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData.rows.map((row: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {/* <TableCell>{row.id}</TableCell> */}
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.publicKey}</TableCell>
                                                <TableCell>{row.currency}</TableCell>
                                                <TableCell>{row.country}</TableCell>
                                                
                                                {isManageLevel ? (
                                                    <>
                                                        <TableCell>{row.PK ? row.PK.slice(-15) : ""}</TableCell>
                                                        <TableCell>{row.SK ? row.SK.slice(-15) : ""}</TableCell>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {isAdmin ? (
                                                    <>
                                                        <TableCell>{row.enabled? t("MANAGER.STATUS.ENABLED"): t("MANAGER.STATUS.DISABLED") }</TableCell>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            dialogOpen(row);
                                                        }}
                                                    >
                                                        {isManageLevel ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.VIEW")}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={pageData.count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => {
                                return t("MANAGER.TABLE.CURRENT", {
                                    from,
                                    to,
                                    count,
                                });
                            }}
                            labelRowsPerPage={t("MANAGER.TABLE.PERPAGE")}
                            backIconButtonText={t("MANAGER.TABLE.PER_PAGE")}
                            nextIconButtonText={t("MANAGER.TABLE.NEXT_PAGE")}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default StickyTable;
