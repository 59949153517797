import React from "react";
import TimePicker, { TimePickerValue } from "react-time-picker";

interface TimePickerProps {
    children?: React.ReactNode;
    dir?: string;
    time: number | null | undefined;
    onTimeChange:any;
}
const addPreZero = (num: number): string => {
  return ("00" + num).slice(-2);
};
const transformTimeNumber24h = (value: number | null | undefined): string => {
  if (!value) {
    return "00:00";
  }
  const hour = Math.floor(value / 3600);
  return (
    addPreZero(hour >= 24 ? hour - 24 : hour) +
    ":" +
    addPreZero(Math.floor((value % 3600) / 60))
  );
};
function Timepicker( props: TimePickerProps) {
    const { children, time, onTimeChange,...other } = props;

    const timeChange = (pickedTime: TimePickerValue) => {
        if(pickedTime){
          onTimeChange(Date.parse(`1970-01-01T${pickedTime}:00+0000`) / 1000);
            //const t = pickedTime?hours() * 3600 + pickedTime?.minute() * 60;
        }else{
          onTimeChange(0);
        }
    };
    return (
    <span {...other}>
      <TimePicker disableClock clearIcon={null} locale="en-US"
          value={ transformTimeNumber24h(time) } 
          format="hh:mm aa"
          amPmAriaLabel="Select AM/PM" onChange={timeChange} />
    </span>
    );
}
export type { TimePickerProps as TimeSpanPickerProps };
export { Timepicker };
export default Timepicker;